<template>
  <div id="app">
    <app-header v-if="!isIntro"/>
    <notifications position="center right" classes="tripmeister-notification"/>
    <router-view/>
    <app-footer v-if="!isIntro"/>
    <Feedback :showModal="showFeedbackModal" @on-close="modalOnClose" />
    <cookie-banner/>
    <collection-modal v-if="modalReiseplan" :content="content" @showCreateModal="showCreateModal" @closeModal="modalReiseplan=false, content=null"/>
    <create-collection-modal v-if="modalCreatePlan" :content="content" @closeModal="modalCreatePlan=false, content=null"/>

  </div>
</template>

<script>
  import { bus } from '@/main'

  export default {
    name: 'App',
    data: () => ({
      content: null,
      modalReiseplan: false,
      modalCreatePlan: false,
      showFeedbackModal: false,
    }),
    components: {
      AppHeader: () => import('@/components/layout/AppHeader'),
      AppFooter: () => import('@/components/layout/AppFooter'),
      Feedback: () => import('@/components/modal/feedback.vue'),
      CookieBanner: () => import('@/components/CookieBanner.vue'),
      CollectionModal: () => import('@/components/modal/addContentToCollection/collection.vue'),
      CreateCollectionModal: () => import('@/components/modal/createCollection'),
    },
    created () {
      bus.$on('show-reiseplan-modal', (elem) => {
        if(elem!== null && elem.hasOwnProperty("content")){
          this.content = elem.content;
        }
        this.modalReiseplan = true
      })

      bus.$on('show-create-plan-modal', () => {
        this.modalCreatePlan = true
      })

      bus.$on('show-feedback-modal', () => {
        this.showFeedbackModal = true;
      });

    },
    methods: {
      modalOnClose() {
        this.showFeedbackModal = false;
      },
      showCreateModal () {
        this.modalReiseplan = false
        this.modalCreatePlan = true
      }
    },
    computed: {
      isIntro() {
        return this.$route.path.substring(1) == 'intro' ? true : false;
      }
    }
  }
</script>
<style lang="scss">
  @import '@/scss/_variables.scss';

  .vue-notification-group{
    margin-top: 80px;

    .vue-notification-wrapper{

      .tripmeister-notification{
        margin: 0px 10px 10px 10px;
        padding: 15px 15px;
        color: $primary;
        border-radius: 10px;
        box-shadow: -5px 5px 10px 0px rgba($color: $primary, $alpha: 0.2);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        .notification-title{
          color: $primary;
          font-size: 1.1rem;
          margin-bottom: 5px;
          text-align: center;

        }

        .notification-content{
          color: $text-color;
          font-size: 0.9rem;
          text-align: center;
        }

        &.error {
          border: 2px solid $secondary;
        }

      }
    }
  }
</style>
