import Vue from 'vue'
import Vuex from 'vuex'
import { login, getUser, logout } from '@/api/auth';
import { getCollectionsOfUser } from '@/api/collection';
import { getAllowedValuesForFieldType } from '@/api/fieldType';
import { getAllowedValuesFromFieldTypes } from '@/utils/helpers';
import createPersistedState from "vuex-persistedstate";
import { search } from '@/api/content';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    roles: null,
    categories: null,
    collections: null,
    login_time: null,
    selectedCategories: null,
    selectedDestination: { name: 'Eckernförder Bucht',coords:{lat:54.45147854027789,long:9.983825683593752},zoom:12},
    destinations: [
      { name: 'Eckernförder Bucht',coords:{lat:54.45147854027789,long:9.983825683593752},zoom:12},
    //  { name: 'Ostseefjord Schlei',coords:{lat:54.583759,long:9.758732},zoom:12},
    /*{ name: 'Flensburger Fjord',coords:{lat:54.81809622556344,long:9.541454315185549},zoom:13},
      { name: 'Wikingerstadt Schleswig',coords:{lat:54.456250,long:9.338706},zoom:12},
      { name: 'Kiel Sailing City',coords:{lat:54.35879047390532,long:10.169230974009567},zoom:12},
      { name: 'Lübecker Bucht',coords:{lat:53.992878,long:10.853353},zoom:11},
      { name: 'Grünes Binnenland',coords:{lat:54.458264553506645,long:9.310913085937502},zoom:12}
      */
      ],
    categories: [
      { name: 'Sightseeing'},
      { name: 'Kultur'},
      { name: 'Gastronomie'},
      { name: 'Outdoor & Aktiv'},
      { name: 'Informationen'},
      { name: 'Shopping'},
      { name: 'Öffentliche Orte'},
      { name: 'Freizeit'},
      { name: 'Wellness'},
      { name: 'Unterkünfte'}
      ]
  },
  mutations: {
    SET_SELECTED_CATEGORIES: (state, categories) => {
      state.selectedCategories = categories;
    },
    SET_SELECTED_DESTINATION: (state, destination) => {
      state.selectedDestination = destination;
    },
    SET_TOKEN: (state, token ) => {
      state.token = token;
      sessionStorage.setItem('token', token);
    },
    SET_USER: (state, user ) => {
      state.user = user;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_CATEGORIES: (state, categories ) => {
      state.categories = categories;
    },
    SET_USER_COLLECTIONS: (state, collections) => {
      state.collections = collections;
    },
    SET_LOGIN_TIME: (state, time) => {
      state.login_time = time;
    },
  },
  getters: {
    getSelectedDestination(state) {
      return state.selectedDestination;
    },
    getLoginTime(state) {
      return state.login_time;
    },
    getCollections(state){
      return state.collections;
    },
    getRoles(state) {
      if(state.user !=null && Object.prototype.hasOwnProperty.call(state.user,'roles')){
        return state.user.roles;
      }
      return null;
    },
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      if(state.token === null){
        /*trying to get the token from sessionStorage*/
        var token = sessionStorage.getItem('token');
        /*set the token to the token from sessionStorage - but only if that token is not null*/
        if(token != null && token != "null"){
          state.token=token;
        }
      }
      return state.token;
    },
    getSelectedCategories(state) {
      return state.selectedCategories;
    },
    getDestinations(state) {
      return state.destinations;
    },
    getCategories(state) {
      return state.categories;
    },
  },
  actions: {
    async loadInstitutionsForDestinations({commit,getters}){
    },
    loadUserCollections({commit,getters}){
      /*get the current user*/
      var user = getters.getUser;

      if(user!=null){
        return new Promise((resolve, reject) => {
          getCollectionsOfUser(user)
          .then(response => {
            commit('SET_USER_COLLECTIONS', response);
            resolve(response); 
          })
          .catch(error => {
            console.log(error);

            this.$notify({
              type: 'error',
              duration: 2500,
              title: 'Fehler!',
              text: 'Die Merklisten konnten nicht geladen werden.'
            });

            reject(error.response)
          });

        });
      }
    },
    loadCategories({commit, getters},){
      /*we'll be using the content_type_id 1 - that are the institutions. It's important that changes are made in both ctypes - the institutions and the angebote*/
      var content_type_id = 1;
      /*fieldType to be loaded by tname, lang_id and contenttype_id*/
      var fieldTypeTechnicalName = "kultur_-_kategorien";
      /*load the categories from the server*/
      getAllowedValuesForFieldType({ctype_id: content_type_id, fieldTypeName: fieldTypeTechnicalName ,langcode:getters.getCurrentLangcode}).then(response => {
        /*that should be the fType*/
        var fType = response.data;
        /*check if we have allowed_values*/
        var data = getAllowedValuesFromFieldTypes(fType);
        /*let's add a defaultValue to the beginning of the data Array*/
        if(data!=null){
          data.unshift({'label':'Kategorien', 'value': null, 'id':null});
          data.push({'label':'Alle', 'value': null, 'id':null});
        }
        /*set the Categories in the store*/
        commit('SET_CATEGORIES', data);
      })
      .catch(error => {
        console.log(error);
      });
    },
    loadUser({ commit }) {
      return new Promise((resolve, reject) => {
        getUser().then(response => {
          commit('SET_USER', response.data);
          resolve(response); 
        })
        .catch(error => {
          console.log(error);
          reject(error.response)
        });
      });
    },
    login ({commit},payload) {
      return new Promise((resolve, reject) => {
        /*a new login*/
        login(payload).then(response => {
          /*set the login-time*/
          commit('SET_LOGIN_TIME',Date.now());
          /*set the token*/
          commit('SET_TOKEN', response.data.token);
          /*set the user*/
          commit('SET_USER', response.data.user);
          resolve(response); 
        })
        .catch(error => {
          reject(error.response)
        });
      });
    },
    resetUserAndToken({commit}){
      /*set the token*/
      commit('SET_TOKEN', null);
      /*set the user*/
      commit('SET_USER', null);
      /*set the login-time*/
      commit('SET_LOGIN_TIME', null);
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        /*a new login*/
        logout().then(response => {
          resolve(response); 
        })
        .catch(error => {
          reject(error.response.data)
        })
        .finally(() => {
          commit('SET_TOKEN', null);
          /*set the user*/
          commit('SET_USER', null);
          /*Logout the user*/
          commit('SET_LOGIN_TIME',null);
        });
      });
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
});
